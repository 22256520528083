@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
}
body
{
    font-family: Helvetica, Sans-Serif;
}
.active{
    color: #CBA540;
}
.team .slick-slide > div {
    margin: 0 40px;
}

.team .slick-slide > div:nth-child(1){
    margin-left: 0;
}

.team .slick-dots li button::before{
  color: #CBA540;
  font-size: 12px;
}

.team .slick-dots li.slick-active button:before {
  color: #262262;
}

.testimonal .slick-dots li button::before{
  color: #e3e3e3;
  font-size: 12px;
}

.testimonal .slick-dots li.slick-active button:before {
  color: #CBA540;
}

.team .slick-list {
    margin-right: -40px;
}

.slick-prev {
  visibility: hidden !important;
}
.slick-next {
  visibility: hidden !important;
}

.cinput {
    padding: 8px;
    outline: none;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-top: 5px;
    margin-right: 2%;
  }

  .ainput {
    padding: 8px;
    outline: none;
    margin-top: 5px;
    margin-right: 2%;
  }
  
  .ctextarea{
    padding: 8px;
    outline: none;
    border: 1px solid lightgray;
    border-radius: 5px;
    height: 15vh;
    margin-top: 5px;
    margin-right: 2%;
    resize: none;
  }
  .cSelect{
    padding: 8px;
    outline: none;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-top: 5px;
    margin-right: 2%;
  }
  .atextarea{
    padding: 8px;
    outline: none;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-top: 5px;
    margin-right: 2%;
    resize: none;
  }
  
  .cbutton{
    background-color: #CBA540;
    padding: 8px;
    outline: none;
    border: none;
    border-radius: 7px;
    color: white;
    font-size: 14px;
    margin-top: 5px;
    cursor: pointer;
  }

  .fbutton{
    background-color: #262262;
    padding: 8px;
    outline: none;
    border: none;
    border-radius: 7px;
    color: white;
    font-size: 14px;
    margin-top: 5px;
    cursor: pointer;
  }

  .wbutton{
    background-color: #25D366;
    padding: 8px;
    outline: none;
    border: none;
    border-radius: 7px;
    color: white;
    font-size: 14px;
    margin-top: 5px;
    cursor: pointer;
  }

  .fbutton{
    background-color: #007bff;
    width: 150px;
    padding: 8px;
    outline: none;
    border: none;
    border-radius: 7px;
    color: white;
    font-size: 14px;
    margin-top: 5px;
    cursor: pointer;
  }

  .pbutton{
    background-color: #25D366;
    width: 100px;
    padding: 8px;
    outline: none;
    border: none;
    border-radius: 7px;
    color: white;
    font-size: 14px;
    margin-top: 5px;
    cursor: pointer;
  }

  .visible {
    position: absolute;
    font-size: 16px;
    z-index: 1000;
    cursor: pointer;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #262262 !important; 
    list-style: none;
    font-weight: 500;
 }
.ant-tabs-tab-btn:hover{
  color: #CBA540;
}

.ant-tabs-ink-bar {
  height: 5px;
  background: none !important;
}


.b {
  animation: b 1s infinite;
}

@keyframes b {
  0%{
    opacity: 1;
  }
  25%{
    opacity: 0.5;
  }
  50%{
    opacity: 0.5;
  }
  75%{
    opacity: 0.5;
  }
  100%{
    opacity: 0.5;
  }
}

.i {
  animation: i 1s infinite;
}

@keyframes i {
  0%{
    opacity: 0.5;
  }
  25%{
    opacity: 1;
  }
  50%{
    opacity: 0.5;
  }
  75%{
    opacity: 0.5;
  }
  100%{
    opacity: 0.5;
  }
}

.m {
  animation: m 1s infinite;
}

@keyframes m {
  0%{
    opacity: 0.5;
  }
  25%{
    opacity: 0.5;
  }
  50%{
    opacity: 1;
  }
  75%{
    opacity: 0.5;
  }
  100%{
    opacity: 0.5;
  }
}

.e {
  animation: e 1s infinite;
}

@keyframes e {
  0%{
    opacity: 0.5;
  }
  25%{
    opacity: 0.5;
  }
  50%{
    opacity: 0.5;
  }
  75%{
    opacity: 1;
  }
  100%{
    opacity: 0.5;
  }
}

.r {
  animation: r 1s infinite;
}

@keyframes r {
  0%{
    opacity: 0.5;
  }
  25%{
    opacity: 0.5;
  }
  50%{
    opacity: 0.5;
  }
  75%{
    opacity: 0.5;
  }
  100%{
    opacity: 1;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}
.bannertext{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.stepper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-number {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  z-index: 2;
}

.step-name {
  font-size: 14px;
}

.active .step-number {
  background-color: #007bff;
  color: #fff;
}

.complete .step-number {
  background-color: #28a745;
  color: #fff;
}

.progress-bar {
  position: absolute;
  top: 25%;
  width: 98%;
  left: 0;
  height: 4px;
  background-color: #ccc;
}

.progress {
  height: 100%;
  background-color: #28a745;
  transition: 0.2s ease;
}

.radio-button {
  width: 20px; /* Adjust the size as needed */
  height: 20px; /* Adjust the size as needed */
  margin-right: 10px; /* Adjust spacing */
}